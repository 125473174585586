import { ReportRepository } from "../repositories";
export class ReportProcess {
    private reportRepository: ReportRepository;

    constructor() {
        this.reportRepository = new ReportRepository();
    }

    async getDashboardById(code: string): Promise<string> {
        const response = await this.reportRepository.getReport(code, "dashboard")
        return response.data;
    }

    async getReportById(code: string): Promise<string> {
        const response = await this.reportRepository.getReport(code, "report");
        return response.data;
    }

}
